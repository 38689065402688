
import { AppUserClient, GameClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class CurrentExtractionEndModal extends Vue {

    stages: OM.TextIdentifier[] = [];
    stageEventIdentifier: string = "";

    @Prop() closeCallback: any;

    created(){
        GameClient.getAvailableStages()
        .then(x => {
            this.stages = x;
        })
    }

    candidate() {
        AppUserClient.initializeGame(this.stageEventIdentifier)
        .then(x => {
            this.closeCallback();
        })
    }

}
