
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { StorageServices } from '@/services/StorageServices';
import { AppUserClient, GameClient } from '@/services/Services';
import ErrorModal from '@/modals/errorModal.vue';
import CurrentExtractionEndModal from '../modals/currentExtractionEndModal.vue';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class Profile extends Vue {

    completeName: string = "";
    extractionResume: OM.UserExtractionResume = new OM.UserExtractionResume();
    ranking: OM.UserExtractionResumeVM[] = [];

    questionsCount: number = StorageServices.getQuestionsCount();
    loggedUser: OM.LoggedUserVM = new OM.LoggedUserVM();
    loaded: boolean = false;
    
    created() {
        this.init();
    }

    init() {
        this.loggedUser = StorageServices.getLoggedUser();
        Promise.all([
            AppUserClient.getLoggedUserCompleteName(),
            GameClient.getCurrentExtraction(),
            GameClient.ranking()
        ])
        .then(xs => {
            this.completeName = xs[0];
            this.extractionResume = xs[1];
            this.ranking = xs[2];

            console.log("sdfljsdlfkj")
            console.log(this.extractionResume)
            if(!this.extractionResume) {
                this.$opModal.show(CurrentExtractionEndModal, {
                    closeCallback: () => {
                        this.$opModal.closeLast();
                        this.init();
                    }
                })
            }

            this.loaded = true;
        })
    }

    get rankingPosition() {
        var foundIndex = this.ranking.findIndex(x => x.userIdentifier == this.loggedUser.identifier);
        if(foundIndex == -1)
            return "--";

        return (foundIndex + 1).toString() + '°';
    }

    goToQuiz() {
        if(this.extractionResume.userAnswers.length < this.questionsCount)
            this.$router.push('/quiz');
        else {
            this.$opModal.show(ErrorModal, {
                title: this.$localizationService.getLocalizedValue('app_Hai risposto a tutte le domande', 'Hai risposto a tutte le domande'),
                firstButtonText: this.$localizationService.getLocalizedValue('app_Classifica', 'Classifica'),
                callback1: () => {
                    this.$opModal.closeLast();
                    this.$router.push('/ranking');
                }
            })
        }
    }

    logout() {
        LoginServices.logout();
    }

}
